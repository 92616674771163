import { template } from "@ember/template-compiler";
import './trip-details-tab.css';
import { array } from '@ember/helper';
import { concat, get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { sum } from 'ember-math-helpers/helpers/sum';
import { modifier } from 'ember-modifier';
import PhAirplane from 'ember-phosphor-icons/components/ph-airplane';
import PhAirplaneLanding from 'ember-phosphor-icons/components/ph-airplane-landing';
import PhAirplaneTakeOff from 'ember-phosphor-icons/components/ph-airplane-takeoff';
import PhFilePdf from 'ember-phosphor-icons/components/ph-file-pdf';
import PhInfo from 'ember-phosphor-icons/components/ph-info';
import PhListChecks from 'ember-phosphor-icons/components/ph-list-checks';
import PhMapPinArea from 'ember-phosphor-icons/components/ph-map-pin-area';
import PhStudent from 'ember-phosphor-icons/components/ph-student';
import PhUser from 'ember-phosphor-icons/components/ph-user';
import { Modal } from 'ember-primitives';
import { and, eq, gt, lt } from 'ember-truth-helpers';
import GoogleMap from 'vfc-client/components/google-map';
import { Accordion } from 'vfc-client/components/ui/accordion';
import Badge from 'vfc-client/components/ui/badge';
import ButtonIcon from 'vfc-client/components/ui/button/icon';
import ButtonText from 'vfc-client/components/ui/button/text';
import Loader from 'vfc-client/components/ui/loader';
import ShowMore from 'vfc-client/components/ui/show-more';
import config from 'vfc-client/config/environment';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type { Trip } from 'vfc-client/types/trip';
import { AuthenticatedRemoteData } from 'vfc-client/utils/authenticated-remote-data';
import { formatDate } from 'vfc-client/utils/date-utils';
import { downloadFile } from 'vfc-client/utils/file-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
export default class TripDetailsTab extends Component<{
    Element: HTMLDivElement;
    Args: {
        trip: Trip;
        setDetailsTabAnchor: (anchor: string) => void;
    };
    Blocks: {
        default: [];
    };
}> {
    @service('auth/http-client')
    authenticatedHttpClient: AuthenticatedHttpClient;
    setupAnchorIntersectionObserver = modifier((element1: HTMLElement)=>{
        const sections1 = element1.querySelectorAll('section');
        const hash1 = window.location.hash?.replace('#', '');
        if (hash1) {
            this.args.setDetailsTabAnchor(hash1);
        }
        const observer1 = new IntersectionObserver((entries1)=>{
            entries1.forEach((entry1)=>{
                if (entry1.isIntersecting) {
                    const location1 = window.location.toString().split('#')[0];
                    const oldHash1 = window.location.hash;
                    const target1 = entry1.target;
                    let hash1 = '#' + target1.id;
                    if (!target1.id) {
                        hash1 = '';
                    }
                    if (hash1 != oldHash1) {
                        this.args.setDetailsTabAnchor(target1.id);
                        history.replaceState(null, '', location1 + hash1);
                    }
                }
            });
        }, {
            root: null,
            rootMargin: '-50%',
            threshold: 0
        });
        sections1.forEach((section1)=>observer1.observe(section1));
    });
    @action
    async downloadPDFSummary() {
        const response1 = await this.authenticatedHttpClient.GET<Response>({
            url: urlFor(`/trip/${this.args.trip.id}/download/summary`)
        });
        downloadFile(response1, 'resumen-viaje.pdf');
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <a name="summary-anchor" class="relative -inset-y-12">
      &#8203;{{! invisible space to make the anchor work with (relative -inset-y-12)  - TODO: evaluate better solution }}
    </a>
    <div class="flex flex-col space-y-5" {{this.setupAnchorIntersectionObserver}}>
      {{! detalles }}
      <section id="summary" class="flex flex-col w-full">
        <div class="text-lg font-medium pb-2 flex gap-2 pl-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12H21M3 6H21M9 18H21"
              stroke="#0A1126"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{t "page.trip-details.trip-details-tab.sections.trip-summary.title"}}
        </div>
        <div class="bg-white border-neutral-200 border rounded-3xl w-full p-12">
          <div class="text-4xl font-bold text-neutral-900">
            {{@trip.destination.name}}
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 m-auto">
            <div class="grid grid-cols-1 gap-2 p-5">
              <div class="flex gap-2">
                <PhInfo class="w-6 h-6 flex-shrink-0">
                  <title>
                    {{t "page.trip-details.trip-details-tab.sections.trip-summary.reference"}}
                  </title>
                </PhInfo>
                <span class="text-gray-500">
                  {{t "page.trip-details.trip-details-tab.sections.trip-summary.reference"}}:&nbsp;
                </span>
                <span>
                  {{@trip.reference}}
                </span>
              </div>
              <div class="flex gap-2">
                <PhAirplaneTakeOff class="w-6 h-6 flex-shrink-0">
                  <title>
                    {{t "page.trip-details.trip-details-tab.sections.trip-summary.departure-date"}}
                  </title>
                </PhAirplaneTakeOff>
                <span class="text-gray-500">
                  {{t
                    "page.trip-details.trip-details-tab.sections.trip-summary.departure-date"
                  }}:&nbsp;
                </span>
                <span class="capitalize">
                  {{formatDate @trip.checkIn config.APP.DATE.USER_FRIENDLY_FORMAT}}
                </span>
              </div>
              <div class="flex gap-2">
                <PhAirplaneLanding class="w-6 h-6 flex-shrink-0">
                  <title>
                    {{t "page.trip-details.trip-details-tab.sections.trip-summary.arrival-date"}}
                  </title>
                </PhAirplaneLanding>
                <span class="text-gray-500">
                  {{t
                    "page.trip-details.trip-details-tab.sections.trip-summary.arrival-date"
                  }}:&nbsp;
                </span>
                <span>
                  {{formatDate @trip.checkOut config.APP.DATE.USER_FRIENDLY_FORMAT}}
                </span>
              </div>
              <div class="flex gap-2">
                <PhStudent class="w-6 h-6 flex-shrink-0">
                  <title>
                    {{t "page.trip-details.trip-details-tab.sections.trip-summary.total-students"}}
                  </title>
                </PhStudent>
                <span class="text-gray-500">
                  {{t
                    "page.trip-details.trip-details-tab.sections.trip-summary.total-students"
                  }}:&nbsp;
                </span>
                <span>
                  {{@trip.totalStudents}}
                </span>
              </div>
              <div class="flex gap-2">
                <PhUser class="w-6 h-6 flex-shrink-0">
                  <title>
                    {{t "page.trip-details.trip-details-tab.sections.trip-summary.total-teachers"}}
                  </title>
                </PhUser>
                <span class="text-gray-500">
                  {{t
                    "page.trip-details.trip-details-tab.sections.trip-summary.total-teachers"
                  }}:&nbsp;
                </span>
                <span>
                  {{@trip.totalTeachers}}
                </span>
              </div>
              {{#if @trip.summaryPdf}}
                <div class="flex justify-center pt-8">
                  <ButtonText
                    class="flex gap-2"
                    @kind="outline"
                    {{on "click" this.downloadPDFSummary}}
                  >
                    <PhFilePdf class="w-6 h-6">
                      <title>
                        {{t
                          "page.trip-details.trip-details-tab.sections.trip-summary.summary-trip-pdf"
                        }}
                      </title>
                    </PhFilePdf>
                    <span>
                      {{t
                        "page.trip-details.trip-details-tab.sections.trip-summary.summary-trip-pdf"
                      }}
                    </span>
                  </ButtonText>
                </div>
              {{/if}}
            </div>
            <div class="hidden lg:inline-block relative top-5">
              <PhMapPinArea class="absolute -left-8 -top-2 w-8 h-8" />
              <PhAirplane class="absolute w-8 h-8 left-28 rotate-[198deg] top-16 bg-white" />
              <img src="/icons/path-arrow.svg" />
            </div>
            {{! image }}
            <div>
              {{#if @trip.image}}
                <img
                  src="{{@trip.image}}"
                  class="hidden md:block rounded-xl w-96 aspect-video object-cover shadow-lg shadow-slate-300"
                />
              {{/if}}
            </div>
          </div>
        </div>
      </section>
      {{! alojamiento }}
      {{#let @trip.accommodation as |accommodation|}}
        <a name="accommodation-anchor" />
        <section id="accommodation" class="flex flex-col w-full pt-4">
          <div class="text-lg font-medium pb-2 flex gap-2 pl-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 14.2864C3.14864 15.1031 2 16.2412 2 17.5C2 19.9853 6.47715 22 12 22C17.5228 22 22 19.9853 22 17.5C22 16.2412 20.8514 15.1031 19 14.2864M18 8C18 12.0637 13.5 14 12 17C10.5 14 6 12.0637 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
                stroke="#0A1126"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{t "page.trip-details.trip-details-tab.sections.room-details.title"}}
          </div>
          <div class="bg-white border-neutral-200 border rounded-3xl w-full p-12">
            <div class="m-auto">
              <ShowMore @fadeEffect={{true}} @maxHeight={{"max-h-[30vw]"}}>
                <div class="w-full">
                  <div class="text-4xl font-bold text-neutral-900">
                    {{accommodation.details.name}}
                  </div>
                  <div class="text-xs uppercase text-neutral-900/75 font-semibold">
                    {{accommodation.details.type}}
                  </div>
                  <div>
                    {{accommodation.meal}}
                  </div>
                  <hr class="bg-neutral-200" />
                  {{#if accommodation.details.distance}}
                    <div class="pt-4 text-sm font-semibold">
                      {{t
                        "page.trip-details.trip-details-tab.sections.room-details.distance-to-center"
                      }}:
                      {{accommodation.details.distance}}
                    </div>
                  {{/if}}
                  {{! features }}
                  <div class="flex gap-2 py-2">
                    {{#each accommodation.details.tags as |tag|}}
                      <Badge class="text-accent1-600 bg-accent1-100">
                        {{tag.name}}
                      </Badge>
                    {{/each}}
                  </div>
                  {{#let accommodation.details.photos as |photos|}}
                    {{#if (gt photos.length 0)}}
                      <div class="py-4 relative grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <img
                          src="{{get (get photos '0') 'uri'}}"
                          class="rounded-xl w-full aspect-video object-cover"
                        />
                        <div class="hidden sm:grid grid-cols-2 gap-2 relative">
                          {{#each photos as |photo index|}}
                            {{#if (and (gt index 0) (lt index 5))}}
                              <img
                                src="{{get photo 'uri'}}"
                                class="rounded-xl w-full aspect-video object-cover"
                              />
                            {{/if}}
                          {{/each}}
                          <Modal as |m|>
                            <button
                              class="z-10 flex gap-1 items-center absolute right-5 bottom-5 rounded-full bg-white text-neutral-900 fill-neutral-900 hover:bg-neutral-900 hover:text-white hover:fill-white px-3 py-2 border border-neutral-900"
                              {{on "click" m.open}}
                            >
                              <svg
                                width="24"
                                height="24"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 256"
                              >
                                <rect width="256" height="256" fill="none" />
                                <circle cx="60" cy="92" r="12" />
                                <circle cx="128" cy="92" r="12" />
                                <circle cx="196" cy="92" r="12" />
                                <circle cx="60" cy="164" r="12" />
                                <circle cx="128" cy="164" r="12" />
                                <circle cx="196" cy="164" r="12" />
                              </svg>
                              {{t "page.trip-details.trip-details-tab.show-all-pictures"}}
                            </button>
                            <m.Dialog class="rounded-lg">
                              <div class="flex justify-end p-2">
                                <ButtonIcon @size="xsmall" {{on "click" m.close}}>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18 6L6 18M6 6L18 18"
                                      stroke="#0A1126"
                                      stroke-width="1.75"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </ButtonIcon>
                              </div>
                              <div class="rounded-lg p-5 flex flex-col gap-4">
                                {{#each photos as |photo|}}
                                  <img
                                    src="{{get photo 'uri'}}"
                                    class="rounded-xl w-full aspect-video object-cover"
                                  />
                                {{/each}}
                              </div>
                            </m.Dialog>
                          </Modal>
                        </div>
                      </div>
                    {{/if}}
                  {{/let}}
                  {{! text }}
                  {{htmlSafe accommodation.description}}
                </div>
                {{! map }}
                <div class="mt-2">
                  <div class="font-semibold text-lg">
                    {{t "page.trip-details.trip-details-tab.sections.room-details.location"}}
                  </div>
                  <div class="pt-2 pb-4">
                    <GoogleMap
                      class="h-60 rounded-xl"
                      @lng={{get accommodation.details.coordinates.coordinates 0}}
                      @lat={{get accommodation.details.coordinates.coordinates 1}}
                      @zoom={{15}}
                    />
                  </div>
                </div>
              </ShowMore>
            </div>
          </div>
        </section>
      {{/let}}
      {{! transporte }}
      {{#let @trip.transport as |transport|}}
        <a name="transport-anchor" />
        <section id="transport" class="flex flex-col w-full pt-4">
          <div class="text-lg font-medium pb-2 flex gap-2 pl-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.24999 20.25H15.75M1.58691 13.1596L5.12085 16.4727C5.5917 16.9141 6.19235 17.1919 6.83362 17.2648C7.47489 17.3378 8.12258 17.202 8.68054 16.8776L22.875 8.62499L21.1276 6.48924C20.6742 5.93517 20.0377 5.56147 19.3329 5.43565C18.6282 5.30983 17.9017 5.44018 17.2846 5.80315L13.125 8.24999L7.49999 6.37499L5.95321 7.0379C5.83749 7.08749 5.73612 7.16542 5.65843 7.2645C5.58075 7.36358 5.52926 7.48062 5.50871 7.60484C5.48816 7.72905 5.49921 7.85644 5.54084 7.97526C5.58247 8.09408 5.65335 8.2005 5.74693 8.28473L8.62499 10.875L5.99999 12.375L3.37499 11.25L1.80443 11.9231C1.68989 11.9722 1.58939 12.049 1.51201 12.1467C1.43463 12.2444 1.3828 12.3598 1.36121 12.4825C1.33962 12.6052 1.34895 12.7314 1.38836 12.8496C1.42776 12.9678 1.496 13.0744 1.58691 13.1596Z"
                stroke="#0A1126"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{t "page.trip-details.trip-details-tab.sections.transport-details.title"}}
          </div>
          <div class="bg-white border-neutral-200 border rounded-3xl w-full p-12">
            {{#if transport}}
              <div class="m-auto">
                <ShowMore @fadeEffect={{true}} @maxHeight={{"max-h-[30vw]"}}>
                  <div class="w-full">
                    <div class="text-4xl font-bold text-neutral-900">
                      {{transport.name}}
                    </div>
                    <div class="text-xs uppercase text-neutral-900/75 font-semibold">
                      {{transport.origin}}
                    </div>
                    <div class="flex gap-2 py-2">
                      {{#each transport.extras as |extra|}}
                        <Badge class="text-accent1-600 bg-accent1-100">
                          {{extra.name}}
                        </Badge>
                      {{/each}}
                    </div>
                    <div class="py-4">
                      <hr class="bg-neutral-200" />
                    </div>
                    {{htmlSafe transport.description}}
                  </div>
                </ShowMore>
              </div>
            {{else}}
              <span class="flex gap-2">
                <PhInfo class="w-6 h-6 flex-shrink-0" />
                Este viaje no tiene transporte incluido
              </span>
            {{/if}}
          </div>
        </section>
      {{/let}}
      {{! extras }}
      <a name="extras-anchor" class="relative -inset-y-24">
        &#8203;{{! invisible space to make the anchor work with (relative -inset-y-12)  - TODO: evaluate better solution }}
      </a>
      <section id="extras" class="flex flex-col w-full pt-4">
        <div class="text-lg font-medium pb-2 flex gap-2 pl-4">
          <PhListChecks class="w-6 h-6" />
          {{t "page.trip-details.trip-details-tab.sections.extras-details.title"}}
        </div>
        <div class="bg-white border-neutral-200 border rounded-3xl w-full p-12">
          <div class="m-auto">
            {{#if @trip.extras.length}}
              {{#each @trip.extras as |extra index|}}
                <Accordion class="{{if (gt index 0) 'py-6'}}" as |accordion|>
                  <accordion.Summary class="list-none group">
                    <div
                      class="flex items-center justify-between py-4 border-b border-neutral-200 cursor-pointer"
                    >
                      <h4>
                        {{extra.name}}
                      </h4>
                      {{! we CANNOT use a <ButtonIcon @kind=ghost @size=xsmall> because the button is not propagating the event to the summary element }}
                      <div
                        class="rounded-md bg-transparent text-neutral-900 hover:bg-neutral-100 w-6 h-6"
                      >
                        {{#if accordion.isOpen}}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 12H19"
                              stroke="#0A1126"
                              stroke-width="1.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        {{else}}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 5V19M5 12H19"
                              stroke="#0A1126"
                              stroke-width="1.75"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        {{/if}}
                      </div>
                    </div>
                  </accordion.Summary>
                  <div class="pt-2">
                    {{#each extra.extras as |ee|}}
                      <Badge class="text-accent1-600 bg-accent1-100">
                        {{ee.name}}
                      </Badge>
                    {{/each}}
                  </div>
                  {{#if accordion.isOpen}}
                    <div class="py-6">
                      {{! details }}
                      {{htmlSafe extra.description}}
                    </div>
                  {{/if}}
                </Accordion>
              {{/each}}
            {{else}}
              <span class="flex gap-2">
                <PhInfo class="w-6 h-6 flex-shrink-0" />
                Este viaje no tiene extras incluidos
              </span>
            {{/if}}
          </div>
        </div>
      </section>
      {{! actividades }}
      <a name="activities-anchor" />
      <section id="activities" class="flex flex-col w-full pt-4">
        <div class="text-lg font-medium pb-2 flex gap-2 pl-4">
          <svg
            width="24"
            height="24"
            data-slot="icon"
            fill="none"
            stroke-width="1.5"
            stroke="#0A1126"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            ></path>
          </svg>
          {{t "page.trip-details.trip-details-tab.sections.activities-details.title"}}
        </div>
        <a name="activities-anchor" />
        <div class="bg-white border-neutral-200 border rounded-3xl w-full p-12">
          <div class="m-auto">
            <div class="text-lg pb-4">
              {{t
                "page.trip-details.trip-details-tab.sections.activities-details.activities-calendar"
              }}
            </div>
            <div class="grid gap-2 lg:gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              {{#let
                (AuthenticatedRemoteData (urlFor (concat "/trip/" @trip.id "/activities")))
                as |requestedActivities|
              }}
                {{#if requestedActivities.isLoading}}
                  <Loader />
                {{else}}
                  {{#each requestedActivities.value as |activity index|}}
                    <div
                      class="flex w-[max(12rem,100%)] border border-gray-300 rounded-xl divide-x divide-gray-300 min-h-36"
                    >
                      {{! date }}
                      <div
                        class="flex flex-col text-xs text-accent1-600 bg-accent1-100 rounded-tl-lg rounded-bl-lg"
                      >
                        <span class="font-semibold pt-2 px-3 whitespace-nowrap uppercase">
                          {{t "page.trip-details.trip-details-tab.day"}}
                          {{sum (array index 1)}}
                        </span>
                        <span class="pt-2 px-3 whitespace-nowrap">
                          {{(formatDate activity.date "D MMM")}}
                        </span>
                      </div>
                      {{! mid up / mid down / full }}
                      <div
                        class="grid grid-rows-[repeat({{activity.slots.length}},minmax(0,1fr))] divide-y divide-gray-300 w-full"
                      >
                        {{#each activity.slots as |slot index|}}
                          <div
                            class="flex items-center justify-center p-2 text-center
                              {{if slot.activity '!border-l-accent1-300 border-l-2'}}
                              {{unless slot.name 'bg-gray-50'}}
                              {{if (eq index 0) 'rounded-tr-lg'}}
                              {{if
                                (eq (sum (array index 1)) activity.slots.length)
                                'rounded-br-lg'
                              }}"
                          >
                            <div class="line-clamp-2 flex gap-2">
                              {{#if slot.icon}}
                                <img src="/icons/{{slot.icon}}.svg" />
                              {{/if}}
                              {{#if slot.name}}
                                <span class="{{if slot.activity 'font-semibold'}}">
                                  {{slot.name}}
                                </span>
                              {{else}}
                                {{t "page.trip-details.trip-details-tab.free-slot"}}
                              {{/if}}
                            </div>
                          </div>
                        {{/each}}
                      </div>
                    </div>
                  {{/each}}
                {{/if}}
              {{/let}}
            </div>
          </div>
        </div>
      </section>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
