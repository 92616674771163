import { template } from "@ember/template-compiler";
import './payments-tab.css';
import type { TOC } from '@ember/component/template-only';
import { concat } from '@ember/helper';
import { get } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import type { Column, Row } from 'ember-headless-table';
import { headlessTable } from 'ember-headless-table';
import { forColumn, Metadata } from 'ember-headless-table/plugins/metadata';
import type { IntlService } from 'ember-intl';
import { t } from 'ember-intl';
import PhFilePdf from 'ember-phosphor-icons/components/ph-file-pdf';
import { resource, type ResourceAPI, use } from 'ember-resources';
import { TrackedObject } from 'tracked-built-ins';
import { Accordion } from 'vfc-client/components/ui/accordion';
import ButtonText from 'vfc-client/components/ui/button/text';
import Loader from 'vfc-client/components/ui/loader';
import config from 'vfc-client/config/environment';
import type AuthenticatedHttpClient from 'vfc-client/services/auth/http-client';
import type { Trip } from 'vfc-client/types/trip';
import { formatDate } from 'vfc-client/utils/date-utils';
import { downloadFile } from 'vfc-client/utils/file-utils';
import { urlFor } from 'vfc-client/utils/url-utils';
type Payments = {
    scheduled: Array<{
        id: number;
        date: string;
        amount: number;
        paid: boolean;
        description: string;
        tripId: number;
        createdAt: string;
        updatedAt: string;
    }>;
    refund: Array<{
        id: number;
        date: string;
        amount: number;
        description: string;
        observations: string;
    }>;
    payments: Array<unknown>;
};
/**
 * Payments tab
 */ interface PaymentsTabSignature {
    Element: HTMLDivElement;
    Args: {
        trip: Trip;
    };
    Blocks: {
        default: [];
    };
}
const INTL_COLUMNS_PREFIX = 'page.trip-details.payments-tab.table.payments.columns';
const INTL_REFUND_COLUMNS_PREFIX = 'page.trip-details.payments-tab.table.refund.columns';
const isFormatDate = (column1: Column<PaymentTableColumn>)=>{
    return forColumn(column1, 'formatDate');
};
const isFormatCurrency = (column1: Column<PaymentTableColumn>)=>{
    return forColumn(column1, 'formatCurrency');
};
const TableColumns: TOC<{
    Element: Array<HTMLDivElement>;
    Args: {
        row: Row;
        columns: Array<Column>;
    };
}> = template(`
  {{#each @columns as |column|}}
    <div class="p-2" scope="row" data-label="{{column.name}}">
      {{#let (isFormatDate column) (isFormatCurrency column) as |formatDateValue formatCurrency|}}
        {{#if column.Cell}}
          <column.Cell @data={{@row.data}} @column={{column}} />
        {{else if formatDateValue}}
          {{formatDate (column.getValueForRow @row) formatDateValue}}
        {{else}}
          {{column.getValueForRow @row}}{{#if formatCurrency}}
            {{formatCurrency}}
          {{/if}}
        {{/if}}
      {{/let}}
    </div>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const TotalPaidCustomCell: TOC<{
    Element: HTMLDivElement;
}> = template(`
  {{! @glint-nocheck: not typesafe yet }}
  <div class="grid grid-flow-col grid-cols-1 sm:grid-cols-[50%_50%] text-right gap-2">
    <span>
      {{get @data @column.key}}€
    </span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
type PaymentTableColumn = {
    id: number;
    date: string;
    amount: number;
    paid: boolean;
    description: string;
    observations: string;
    tripId: number;
    createdAt: string;
    updatedAt: string;
};
type PaymentRefundTableColumn = {
    id: number;
    date: string;
    amount: number;
    description: string;
    tripId: number;
};
let PaymentsTab = class PaymentsTab extends Component<PaymentsTabSignature> {
    @service('auth/http-client')
    httpClient: AuthenticatedHttpClient;
    @service
    intl: IntlService;
    paymentsTable = headlessTable<PaymentTableColumn>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.date`),
                    key: 'date',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.USER_FRIENDLY_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.amount`),
                    key: 'amount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatCurrency: '€'
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.total-paid`),
                    key: 'totalPaid',
                    Cell: TotalPaidCustomCell
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.pending-paid`),
                    key: 'pendingPaid',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatCurrency: '€'
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_COLUMNS_PREFIX}.description`),
                    key: 'description'
                }
            ],
        data: ()=>{
            return (this.paymentsResource?.value?.scheduled?.map((schedule1)=>{
                const totalPaid1 = schedule1.partialPayments.reduce((acc1, pp1)=>{
                    acc1 = acc1 + pp1.amount;
                    return acc1;
                }, 0);
                schedule1['totalPaid'] = totalPaid1;
                schedule1['pendingPaid'] = schedule1.amount - totalPaid1;
                return schedule1;
            }) ?? []);
        }
    });
    refundPaymentsTable = headlessTable<PaymentRefundTableColumn>(this, {
        columns: ()=>[
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.date`),
                    key: 'date',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatDate: config.APP.DATE.USER_FRIENDLY_FORMAT
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.amount`),
                    key: 'amount',
                    pluginOptions: [
                        Metadata.forColumn(()=>({
                                formatCurrency: '€'
                            }))
                    ]
                },
                {
                    name: this.intl.t(`${INTL_REFUND_COLUMNS_PREFIX}.description`),
                    key: 'description'
                }
            ],
        data: ()=>{
            return this.paymentsResource?.value?.refund ?? [];
        }
    });
    get refundPayments() {
        return this.paymentsResource?.value?.refund ?? [];
    }
    @use
    paymentsResource = resource((api1: ResourceAPI)=>{
        let { on: on1 } = api1;
        let state1 = new TrackedObject<{
            value?: Payments;
            error?: Error;
            loading: boolean;
        }>({
            loading: true
        });
        let controller1 = new AbortController();
        on1.cleanup(()=>controller1.abort());
        this.httpClient.GET<Payments>({
            url: urlFor(`/trip/${this.args.trip.id}/payments`),
            headers: new Headers({
                Accept: 'application/json'
            })
        }).then((payments1)=>{
            state1.value = payments1;
        }).catch((error1)=>{
            state1.error = error1;
        }).finally(()=>(state1.loading = false));
        return state1;
    });
    @action
    async downloadPDFProforma() {
        const response1 = await this.httpClient.GET<Response>({
            url: urlFor(`/trip/${this.args.trip.id}/download/proforma`)
        });
        downloadFile(response1, 'proforma.pdf');
    }
    static{
        template(`
    {{! @glint-nocheck: not typesafe yet }}
    <section id="payments" class="w-full">
      <div class="rounded-3xl w-full bg-white border border-neutral-200 p-12">
        <div class="m-auto">
          <div class="flex justify-between items-center pb-8">
            <h2 class="pb-4">
              {{t "page.trip-details.payments-tab.title"}}
            </h2>

            {{#if @trip.proforma}}
              <ButtonText
                class="flex gap-2"
                @kind="outline"
                {{on "click" this.downloadPDFProforma}}
              >
                <PhFilePdf class="w-6 h-6" />
                <span class="hidden lg:block">
                  {{t "page.trip-details.payments-tab.proforma-dowload"}}
                </span>
              </ButtonText>
            {{/if}}
          </div>
          <div class="mb-5" {{this.paymentsTable.modifiers.container}}>
            <div class="flex flex-col sm:divide-y sm:divide-neutral-200">
              <div class="grid grid-cols-5 gap-2 bg-accent1-100 text-center">
                {{#each this.paymentsTable.columns as |column|}}
                  <div scope="col" class="p-2 text-accent1-600 font-semibold">
                    {{column.name}}
                  </div>
                {{/each}}
              </div>
              <div class="sm:divide-y sm:divide-neutral-200">
                {{#if this.paymentsResource.loading}}
                  <div class="flex justify-center p-2">
                    <Loader />
                  </div>
                {{else if this.paymentsResource.error}}
                  <div class="bg-red-500">
                    <div>
                      Unexpected error has happened
                    </div>
                    <div class="text-sm">
                      {{this.paymentsResource.error.message}}
                    </div>
                  </div>
                {{else}}
                  {{#each this.paymentsTable.rows as |row|}}
                    <div class="cursor-pointer hover:shadow-md text-center">
                      {{#if (isEmpty row.data.partialPayments)}}
                        <div class="grid grid-cols-5 gap-2 sm:flex-row items-center">
                          <TableColumns @row={{row}} @columns={{this.paymentsTable.columns}} />
                        </div>
                      {{else}}
                        <Accordion class="flex flex-col group/accordion" as |accordion|>
                          <accordion.Summary
                            class="grid grid-cols-5 gap-2 sm:flex-row items-center group-open/accordion:bg-accent1-50 group-open/accordion:border-b group-open/accordion:border-dashed group-open/accordion:border-accent1-600"
                          >
                            <TableColumns @row={{row}} @columns={{this.paymentsTable.columns}} />
                          </accordion.Summary>
                          <div class="flex py-3 gap-2">
                            <div class="w-2/5 flex gap-2 justify-center items-center">
                              <svg
                                class="stroke-current text-neutral-900 group-hover:fill-neutral-900 group-hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                  stroke-width="1.75"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span>
                                Desglose de importe pagado
                              </span>
                            </div>
                            <div
                              class="w-3/5 mx-2 border border-dashed border-accent1-500 rounded-lg text-sm"
                            >
                              <div class="py-2">
                                <div class="grid grid-cols-3 text-center">
                                  <div class="px-2 font-semibold underline decoration-accent1-300">
                                    Fecha
                                  </div>
                                  <div class="px-2 font-semibold underline decoration-accent1-300">
                                    Cantidad
                                  </div>
                                  <div class="px-2 font-semibold underline decoration-accent1-300">
                                    Modalidad
                                  </div>
                                </div>
                                {{#each row.data.partialPayments as |pp|}}
                                  <div class="grid grid-cols-3 text-center">
                                    <div class="px-2" data-label="Fecha">
                                      {{formatDate pp.date config.APP.DATE.USER_FRIENDLY_FORMAT}}
                                    </div>
                                    <div class="px-2" data-label="Cantidad">
                                      {{pp.amount}}€
                                    </div>
                                    <div class="px-2" data-label="Modalidad">
                                      {{t (concat "enum.payment-method." pp.tripPayment.method)}}
                                    </div>
                                  </div>
                                {{/each}}
                              </div>
                            </div>
                          </div>
                        </Accordion>
                      {{/if}}
                    </div>
                  {{/each}}
                {{/if}}
              </div>
            </div>
          </div>

          {{#if this.refundPayments}}
            <h3 class="mb-4">
              Devoluciones
            </h3>
            <div class="mb-5" {{this.refundPaymentsTable.modifiers.container}}>
              <div class="flex flex-col sm:divide-y sm:divide-neutral-200">
                <div class="grid grid-cols-5 gap-2 bg-accent1-100 text-center">
                  {{#each this.refundPaymentsTable.columns as |column|}}
                    <div scope="col" class="p-2 text-accent1-600 font-semibold">
                      {{column.name}}
                    </div>
                  {{/each}}
                </div>
                <div class="sm:divide-y sm:divide-neutral-200">
                  {{#each this.refundPaymentsTable.rows as |row|}}
                    <div class="grid grid-cols-5 gap-2 sm:flex-row items-center text-center">
                      <TableColumns @row={{row}} @columns={{this.refundPaymentsTable.columns}} />
                    </div>
                  {{/each}}
                </div>
              </div>
            </div>
          {{/if}}

          <div class="py-4">
            <h2 class="pb-4">
              {{t "page.trip-details.payments-tab.my-invoices"}}
            </h2>
            <div>
              No hay facturas.
            </div>
          </div>
          <div class="py-4">
            {{htmlSafe this.paymentsResource.value.information}}
          </div>
        </div>
      </div>
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default PaymentsTab;
